import { isSupportedLanguage } from "@/i18n/SupportLanguages"

/**
 * 批量导入语言包
 * @param langList  需要加载的语言列表
 */
const loadLangPackages = (langList: string[]) => {
  const _resources: {
    [key: string]: any
  } = {}
  const modules = import.meta.glob("./*/*.json", { eager: true })

  for (const localCode of langList) {
    _resources[localCode] = {}
  }

  for (const path in modules) {
    const _path = path.replace("./", "")
      .replace(".json", "")

    /**
     * @param localCode 语言简码，限定为 2 个字符，如 en、zh、ja
     * @param namespace 命名空间，提取 common.json 中的 common
     */
    const [localCode, namespace] = _path.split("/", 2)

    _resources[localCode] && (_resources[localCode][namespace] = modules[path])
  }

  return _resources
}

// 如果新增了语言，请在 SupportLanguages.ts 文件添加
export const resources = loadLangPackages([
  "en", "zh",
  "zh-HK", // 2022-11-28 新增语言
  "de", "es",
  "fr", "it", "pt",
  "nl", "sv", // 2022-11-28 新增语言

  "da", "cs", "no", "nb", "pl", "fi", "tr", "th", "ja", "ko", "pt-BR", // 2023-11-28新增语言
])

/**
 * 20种语言种暂时发现四种语言和Shopify定义的枚举不同，故加次数据集，便于传给Shopify
 */
export const differenceLangMap: Record<string, string> = {
  "en": "EN_US",
  "zh": "ZH_CN",
  "zh-HK": "ZH_TW",
  "pt-BR": "PT_BR",
}

export const shopifyLangMap: Record<string, string> = {
  "zh-CN": "zh",
  "zh-TW": "zh-HK",
  "pt-PT": "pt",
  "cs-CZ": "cs",
  "da-DK": "da",
  "de-DE": "de",
  "en-US": "en",
  "es-ES": "es",
  "fi-FI": "fi",
  "fr-FR": "fr",
  "it-IT": "it",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "nb-NO": "nb",
  "nl-NL": "nl",
  "pl-PL": "pl",
  "pt-BR": "pt-BR",
  "sv-SE": "sv",
  "th-TH": "th",
  "tr-TR": "tr",
}

export const getLocaleByShopifyCode = (code: string) => {
  if(!code) return code

  if(shopifyLangMap[code]){
    return shopifyLangMap[code]
  }else if(isSupportedLanguage(code.split("-")[0])){
    return code.split("-")[0]
  }else{
    return code
  }
}
